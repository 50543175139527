<template>
	<el-table-column
		:type="type"
		:index="index"
		:label="label"
		:column-key="columnKey"
		:prop="prop"
		:width="width"
		:min-width="minWidth"
        :input-style="inputStyle"
		@render-header="renderHeader"
		:fixed="fixed"
		:sortable="sortable"
		@sort-method="sortMethod"
		:sort-by="sortBy"
		:sort-orders="sortOrders"
		:resizable="resizable"
		@formatter="formatter"
		:show-overflow-tooltip="showOverflowTooltip"
		:align="align"
		:header-align="headerAlign"
		:class-name="className"
		:label-class-name="labelClassName"
		@selectable="selectable"
		:reserve-selection="reserveSelection"
		:filters="filters"
		:filter-placement="filterPlacement"
		:filter-multiple="filterMultiple"
		:filtered-value="filteredValue"
		@filter-method="filterMethod"
		style="width:100%"
	>
		<template #header="header" v-if="typeof $slots.header === 'function'">
			<slot name="header" :column="header.column"></slot>
		</template>
		<template #default="scope">
			<div v-if="showSlot">
				<slot
					:store="scope.store"
					:row="scope.row"
					:column="scope.column"
					:$index="scope.$index"
					:_self="scope._self"
				></slot>
			</div>
			<lida-table-col
				:row="scope.row"
				:prop="prop"
				:popover-width="popoverWidth"
				v-else/>
			<div class="disabled" v-if="scope.row.disabled && label !== '操作'"></div>
		</template>
	</el-table-column>
</template>
<script>
	import lidaTableCol from "./lida-table-col.vue"
	import {
		getLabelWidth,  //获取列的宽度
	} from "@/common/js/common.js"
	export default{
		data(){
			return{
				isShowPopover:false,
			}
		},
		components:{
			lidaTableCol,
		},
		props:{
      inputStyle:Object,
			type:String,
			index:Number,
			label:String,
			columnKey:String,
			prop:String,
			width:{
				type:[String,Number],
			},
			minWidth:{
				type:[String,Number],
				default:(row) => {
					return getLabelWidth(row.label);
				}
			},
			fixed:[String,Boolean],
			sortable:{
				type:[String,Boolean],
				default:false,
			},
			sortBy:[String,Array],
			sortOrders:{
				type:Array,
				default:() => {
					return ['ascending', 'descending', null];
				}
			},
			resizable:{
				type:Boolean,
				default:true,
			},
			showOverflowTooltip:{
				type:Boolean,
				default:false,
			},
			align:{
				type:String,
				default:"center",
			},
			headerAlign:String,
			className:String,
			labelClassName:String,
			reserveSelection:{
				type:Boolean,
				default:false,
			},
			filters:Array,
			filterPlacement:String,
			filterMultiple:{
				type:Boolean,
				default:true,
			},
			filteredValue:Array,
			popoverWidth:{ //当内容超出时提示框的宽度
				type:[Number,String],
				default:200,  //默认宽度200
			},
			disabled:Boolean, //是否禁用
		},
		computed:{
			showSlot(){
				return !!this.$slots['_'];
			},
		},
		methods:{
			renderHeader(column,index){
				this.$emit("renderHeader",column,index);
			},
			sortMethod(a,b){
				this.$emit("sortMethod",a,b);
			},
			formatter(row, column, cellValue, index){
				this.$emit("formatter",row,column,cellValue,index);
			},
			selectable(row, index){
				this.$emit("selectable",row,index);
			},
			filterMethod(value, row, column){
				this.$emit("filterMethod",value, row, column);
			},
		},
	}
</script>

<style scoped lang="less">
	/deep/ .el-table__row td{
		position: relative;
	}
	.disabled{
		position: absolute;
		background: rgba(255,255,255,.7);
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
	}
</style>
